import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { HomeComponent } from './home-component/home-component.component';
import { appRoutes } from './app.route';
import { RouterModule } from "@angular/router";
import { EmployeeListService} from './employee-list.service';
import { EditComponent } from './edit/edit.component';
import { AddComponent } from './add/add.component';
import { FormsModule }   from '@angular/forms';
import { SearchFilter } from './filter-pipe';
import { ModalModule } from 'ngx-bootstrap';
import { DeleteModalComponent } from './delete-modal/delete-modal.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    EditComponent,
    AddComponent,
    SearchFilter,
    DeleteModalComponent
  ],
  imports: [
    ModalModule.forRoot(),
    FormsModule,
    RouterModule.forRoot(
      appRoutes),
    BrowserModule
  ],
  exports:[SearchFilter],
  entryComponents:[DeleteModalComponent],
  providers: [EmployeeListService],
  bootstrap: [AppComponent]
})
export class AppModule { }
