
export const EmployeeList: employee[] = [{
			  "id" : 1234,
			"Name" : "Sanket",
			"Salary" : "8,00,000",
			"Designation" : "Front End Developer"
				},
				{
					"id": 4567,
			"Name" : "Rohit",
			"Salary" : "4,00,000",
			"Designation" : "Software Engineer"
				}];

export interface employee{
	id : number,
	Name : string,
	Salary : string,
	Designation : string
}
