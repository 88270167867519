import { Component, OnInit } from '@angular/core';
import {EmployeeList , employee} from '../EmployeeList';
import { EmployeeListService } from '../employee-list.service'
import { Router } from "@angular/router";
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { DeleteModalComponent } from '../delete-modal/delete-modal.component';
import { Subscription } from "rxjs";

@Component({
  selector: 'app-home-component',
  templateUrl: './home-component.component.html',
  styleUrls: ['./home-component.component.css']
})
export class HomeComponent implements OnInit {
  public EmployeeData;
  bsModalRef: BsModalRef;
  subscriptions: Subscription[] = [];
  constructor( private _employeeListService : EmployeeListService, private router: Router,private modalService: BsModalService) { }

  ngOnInit() {
    this.EmployeeData = this._employeeListService.getEmployeeList();
  }

  editYes(employee){
    this.router.navigate(['/edit',employee.id]);
  }

  onDelete(employee){
    const initialState = employee;
    this.bsModalRef = this.modalService.show(DeleteModalComponent ,{ initialState });
  }
  
  addDetails(){
    this.router.navigate(['/add']);
  }

}
