import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { EmployeeListService } from '../employee-list.service'
import { Router } from '@angular/router';
import { employee } from "src/app/EmployeeList";

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class EditComponent  {

  public id: number;
  public EmployeeData;
  Name:string;
  Salary : string;
  Designation : string;
  
    public constructor(private route: ActivatedRoute,private _employeeListService : EmployeeListService, private router :Router) {
    this.route.params.subscribe( params =>{
             this.id = params["id"];
            this.EmployeeData = this._employeeListService.getEmployeeList();
            this.EmployeeData.forEach((value) => {
              if(value.id == this.id)
              {
                this.Name = value.Name;
                this.Salary = value.Salary;
                this.Designation = value.Designation;
              }
            })
    }
     )
    }

    onSave(){

     	let employeeDetails : employee= { 
         "id" : this.id,
			"Name" : this.Name,
			"Salary" : this.Salary,
			"Designation" : this.Designation
				};
      this._employeeListService.editEmployee(employeeDetails);
      alert('Updated');
      this.router.navigate(['/']);
    }

     cancelAdd(){
     	this.router.navigate(['/']);
     }
     
}
