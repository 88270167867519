import { Component, OnInit } from '@angular/core';
import { BsModalRef } from "ngx-bootstrap";
import { employee} from '../EmployeeList';
import { EmployeeListService } from '../employee-list.service';
import { Router } from "@angular/router";

@Component({
  selector: 'modal-content',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.css']
})
export class DeleteModalComponent implements OnInit {
  constructor(private _employeeListService : EmployeeListService,public bsModalRef: BsModalRef, private router: Router) { }

  ngOnInit() {
  }

  yesDelete(id){
     	this._employeeListService.deleteEmployee(id);
      this.bsModalRef.hide();
     }

}
