import { Injectable } from '@angular/core';
import { EmployeeList } from './EmployeeList'

@Injectable()
export class EmployeeListService {
getEmployeeList()
	{
		return EmployeeList;
	}

	addEmployee(employee){
		EmployeeList.push(employee);
	}

	editEmployee(employee){

      for (var i = 0; i < EmployeeList.length; i++) {
            if (EmployeeList[i].id == employee.id) {
            EmployeeList[i].Name = employee.Name;
             EmployeeList[i].Salary = employee.Salary;
             EmployeeList[i].Designation = employee.Designation;
                        break;
                            }
            }
	}

      deleteEmployee(id){
             for (var i = 0; i < EmployeeList.length; i++) {
            if (EmployeeList[i].id == id) {
                        EmployeeList.splice(i,1);
                        break;
                            }
            }
      }

}
